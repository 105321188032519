import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Layout,
    Button,
    Row,
    Col,
    Typography,
    Form,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Logo from "../../assets/images/logo.svg";
import LoginLogo from "../../assets/images/logo-login.svg";
import VerifiedIcon from "../../assets/images/verified-check.svg";
import useRequest from "../../hooks/useRequest";
import useFirebase from "../../hooks/useFirebase";
import { ShowToast, Severty } from "../../helper/toast";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import apiPath from "../../constants/apiPath";
import encryptDecrypt from "../../helper/encryptDecrypt";
import { Container } from "react-bootstrap";
import { InputOTP } from "antd-input-otp";
const { Title } = Typography;


const OtpIndex = () => {

    const { setIsLoggedIn, setUserProfile } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [form] = Form.useForm();
    const { request } = useRequest();
    const navigate = useNavigate();


    const handleVerifyOTP = (values) => {
        const { otp } = values;
        let payload = { email: email, otp: parseInt(otp.join("")) }

        setLoading(true);
        request({
            url: apiPath.verifyOTP,
            method: "POST",
            data: payload,
            onSuccess: (data) => {
                setLoading(false);
                if (data?.statusText == "true") {
                    setIsLoggedIn(true);
                    setUserProfile(data?.data?.user);
                    localStorage.setItem("token", data?.data?.token);
                    localStorage.setItem("userProfile", JSON.stringify(data?.data?.user));
                    ShowToast(data.message, Severty.SUCCESS);
                    navigate('/dashboard')
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }
            },
            onError: (error) => {
                setLoading(false);
                ShowToast(error.response.data.message, Severty.ERROR);
            },
        });
    };

    const handleResend = () => {
        request({
            url: apiPath.sendOTP,
            method: "POST",
            data: { email: email },
            onSuccess: (data) => {
                if (data.statusText == "true") {
                    ShowToast(data.message, Severty.SUCCESS);
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }
            }
        })
    }

    useEffect(() => {
        const savedEmail = localStorage.getItem("ykmCe2AYEFTHobn");
        var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
        setEmail(originalEmail);
    }, []);

    return (
        <>
            <Layout className="layout-default layout-signin">
                <Container className="signin">
                    <Row className=" align-items-center">
                        <Col span={24} >
                            <div className="header">
                                <img onClick={() => navigate("/")} src={Logo} alt="" />
                            </div>
                        </Col>
                        {/* <Col span={24} >
                            <div className="signup-logo ">
                                <Button
                                    className="prev-pagebtn mt-2"
                                    type="button"
                                    onClick={() => navigate("/login")}
                                    htmlType="submit"
                                >
                                    <ArrowLeftOutlined />  Back
                                </Button>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
                <div className="signin-box">
                    <Row justify="space-around">
                        <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                            <div className="h-sign-menu">

                                <Title
                                    className="tital-text-sign mb-15"
                                    onClick={() => navigate("/")}
                                >
                                    OTP Verification
                                </Title>
                                <p>
                                    Verify your account by entering verification
                                    code we sent to email <Link>{email || "!"}</Link>
                                </p>

                            </div>
                            <div className="signup-form">
                                <Form
                                    id="verify-otp"
                                    form={form}
                                    onFinish={handleVerifyOTP}
                                    autoComplete="off"
                                    layout="vertical"
                                    className="row-col"
                                >
                                    <Form.Item
                                        name="otp"
                                        className="otp-form"
                                        rules={[
                                            { required: true, message: "Please enter the OTP" },
                                        ]}
                                    >
                                        <InputOTP
                                            className="form-of-type"
                                            length={4}
                                            inputType="numeric"
                                        />
                                    </Form.Item>
                                    <div className="forgot-pass">
                                        <Form.Item
                                            name="remember"
                                            className="remember-type-text"
                                            valuePropName="checked"
                                        >
                                            Didn’t receive the code?
                                        </Form.Item>
                                        <div className="user-dirct">
                                            <span onClick={() => handleResend()} className="ac-create">
                                                <Link>Resend OTP</Link>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className="otp-timing">
                                        Resend code in <span>50s</span>
                                    </div> */}
                                    <Form.Item className="signin-footer ">
                                        <Button
                                            className="submit-button "
                                            type="submit"
                                            htmlType="submit"
                                            loading={loading}
                                        >
                                            Continue
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>




            </Layout>
        </>
    );
};

export default OtpIndex;



