import {
  DownOutlined,
  KeyOutlined,
  LogoutOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Upload as UploadAntd,
  message,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { uploadFile } from "react-s3";
import { useNavigate } from "react-router";
import Logo from "../../assets/images/logo.svg";
import UserIcon from "../../assets/images/avatar.png";
import Notification from "../../assets/images/coines.svg";
import DeleteModal from "../../components/DeleteModal";
import apiPath from "../../constants/apiPath";
import { AuthContext } from "../../context/AuthContext";
import { getFileExtension } from "../../helper/functions";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { AppStateContext } from "../../context/AppContext";
import moment from "moment";
const s3Config = {
  region: "us-east-1",
  credentials: {
    accessKeyId: "IJTXH751MBXUWMTWM0SF",
    secretAccessKey: "lfTPRJ2PqAxsp3poTW9YlHktgaL1cFkLup8LidW9",
  },
  endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
  forcePathStyle: true,
};

const s3Client = new S3Client(s3Config);

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ name: sectionHeading, onPress }) {
  const [visible, setVisible] = useState(false);
  const [profile, setProfile] = useState({});
  const [selected, setSelected] = useState();
  const [profileVisible, setProfileVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [appSettingVisible, setAppSettingVisible] = useState(false);
  const [list, setList] = useState([]);
  const { request } = useRequest();
  const [refresh, setRefresh] = useState(false);
  const { logout, userProfile, setIsAdmin } = useContext(AuthContext);
  const { pageHeading } = useContext(AppStateContext);
  const [loading, setLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  useEffect(() => {
    if (!userProfile) return;
    // fetchData();
  }, [userProfile]);

  useEffect(() => window.scrollTo(0, 0));

  const fetchData = () => {
    request({
      url: apiPath.notification,
      method: "GET",
      onSuccess: (data) => {
        setList(data.data.notifications);
        setUnreadCount(data.data.unreadCount);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const readAll = () => {
    request({
      url: apiPath.notificationRead,
      method: "POST",
      onSuccess: (data) => {
        setUnreadCount(0);
        setRefresh((x) => !x);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    if (!isOpen) return document.body.classList.remove("edit-dropdown");
    document.body.classList.add("edit-dropdown");

    return () => {
      document.body.classList.remove("edit-dropdown");
    };
  }, [isOpen]);

  const showDeleteConfirm = (record) => {
    setIsLogoutModalVisible(true);
  };

  const handleMenuClick = (e) => {
    setIsOpen(false);
    if (e.key == 2) {
      setVisible(true);
    }
    if (e.key == 1) {
      setProfileVisible(true);
    }
    if (e.key == 4) {
      setAppSettingVisible(true);
    }
    if (e.key == 3) {
      showDeleteConfirm();
    }
  };

  const handleCreate = (values) => {
    onCreate(values);
  };

  const onCreate = (values) => {
    const { old_password, new_password } = values;
    const payload = {};
    if (!old_password || !new_password)
      return ShowToast("Please enter password ", Severty.ERROR);
    setLoading(true);
    payload.new_password = new_password;
    payload.old_password = old_password;
    request({
      url: apiPath.changePassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setVisible(false);
          logout();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
        ShowToast("Logout Successfully", Severty.SUCCESS);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    request({
      url: apiPath.profile,
      method: "GET",
      onSuccess: (data) => {
        setProfile(data.data);
        setSelected(data.data);
      },
    });
  }, [refresh]);

  return (
    <>
      <Row
        gutter={[24, 0]}
        className="justify-content-between mx-0 align-items-center"
      >
        <Col span={12} lg={12} className="SectionMain px-0">
          <div className="d-none d-lg-block">
            <div className="sectionheadding">
              <h2>{pageHeading || "Dashboard"}</h2>
              <p>Updated on {moment(new Date()).format("hh:mm A")}{", "}{moment(new Date()).format("ll")}</p>
            </div>
          </div>
          <div className="tabLogo d-sm-block d-lg-none">
            <img className=" " src={Logo} />
          </div>
        </Col>
        <Col span={12} lg={12} className="header-control px-0">
       
          <div className="user-profile">
         
            <Image
              width={45}
              height={45}
              style={{ borderRadius: "100%" }}
              src={profile?.image || UserIcon}
            />
            <h6 style={{ marginLeft: -10 }}>
              {profile?.name || "Alexim Cagle"}{" "}
            </h6>
            <div className="toggler-bt d-lg-none ">
          <Button
              type="link"
              className="sidebar-toggler "
              onClick={() => onPress()}
            >
              {toggler}
            </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Header;
