import React, { useEffect } from "react";
import Header from "../../components/layout/Header";
import { Row, Col, Button } from "antd";
import OwlCarousel from "react-owl-carousel3";
import { Container } from "react-bootstrap";
import Footer from "../../components/layout/Footer";
import Coin01 from "../../assets/images/smallcoine01.svg";
import Coin02 from "../../assets/images/smallcoine02.svg";
import Coin03 from "../../assets/images/bigcoine01.svg";
import Coin04 from "../../assets/images/bigcoine02.svg";

import ShapeCenter from "../../assets/images/center-rectangle.png";
import ShapeLeft from "../../assets/images/right-rectangle.png";
import ShapeRight from "../../assets/images/left-rectangle.png";
import AboutImg from "../../assets/images/about-image.png";
import FeatureIcon from "../../assets/images/circalfeature.svg";
import RightCheck from "../../assets/images/right-circal.svg";
import QuotexIcon from "../../assets/images/qotion.svg";
import StarIcon from "../../assets/images/Star-list.svg";
import UserIcon from "../../assets/images/avatar.png";
import FacebookIcon from "../../assets/images/fb.svg";
import InstagramIcon from "../../assets/images/insta.svg";
import TwitterIcon from "../../assets/images/twitter.svg";
import Logo from "../../assets/images/logo.svg";
import MapIcon from "../../assets/images/map.svg";
import MailIcon from "../../assets/images/mail-icon.svg";


function Home() {

  return (
    <>
      <Header />

      <main className="main-content">
        <section className="banner-sec">
          <Container className="    position-relative">
            <div className="left-coins">
              <img className="coin-first01" src={Coin01} />
              <img className="coin-first02" src={Coin02} />
              <img className="coin-first03" src={Coin03} />
              <img className="coin-first04" src={Coin04} />
            </div>
            <div className="header-top">
              <img className="img-colorshape01" src={ShapeLeft} />
              <img className="img-colorshape02" src={ShapeCenter} />
              <img className="img-colorshape03" src={ShapeRight} />
            </div>
            <Row>
              <Col span={24}>
                <div className="sladir-slaide">
                  <div className="head-headding">
                    <span>Lorem ipsum dolor sit amet consectetur</span>
                  </div>
                  <h2> Consequat justo faucibus in nam non </h2>
                  <p>
                    {" "}
                    Consequat justo faucibus in nam non consectetur massa ipsum.
                    Sit et elit varius diam ultrices leo. Quis vitae in nulla
                    ut. Nec viverra dictum ullamcorper
                  </p>
                  <Button className="join-btn-neww">Explore</Button>
                </div>
              </Col>
            </Row>
            <div className="right-coins">
              <img className="coin-first01" src={Coin01} />
              <img className="coin-first02" src={Coin02} />
              <img className="coin-first03" src={Coin03} />
              <img className="coin-first04" src={Coin04} />
            </div>
          </Container>
        </section>
      </main>
      <section className="abouts-section">
        <Container>
          <div className="section-headding">
            <h4>About us</h4>
          </div>
          <Row gutter={32} className="mt-5">
            <Col span={24} md={12}>
              <div className="about-img">
                <img src={AboutImg} />
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="right-about-outer">
                <h4>Lorem Ipsum</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Egestas mauris
                  parturient fames tristique ut dignissim ultrices. Sed
                  suspendisse elementum leo enim potenti sed congue. Nunc nec
                  tellus turpis phasellus maecenas. Erat augue leo nunc dui
                  quisque eget.
                </p>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Egestas mauris
                  parturient fames tristique ut dignissim ultrices. Sed
                  suspendisse elementum leo enim potenti sed congue. Nunc nec
                  tellus turpis phasellus maecenas. Erat augue leo nunc dui
                  quisque eget.
                </p>
                <a>Read More</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="Feature-section">
        <Container>
          <div className="section-headding">
            <h4>Feature</h4>
          </div>
          <div className="feature-item">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={20}
              nav={true}
              dots={true}
              center={true}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 3,
                },
              }}
            >
              <div className="item feature-main">
                <div className="feature-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={FeatureIcon}
                      className="feature-image"
                      alt="Feature"
                    />
                  </div>
                  <h6>Feature</h6>
                  <p className="feature-message">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                  <div className="feature-listed">
                    <h6>What you will get</h6>
                    <ul>
                      <li>
                        <img src={RightCheck} alt="Check" /> Lorem ipsum dolor
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> amet consectetur
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Egestas mauris
                        parturient
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> leo enim potenti
                        sed{" "}
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Nunc nec tellus
                        turpis phasellus
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> o nunc dui quisque
                        eget.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item feature-main">
                <div className="feature-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={FeatureIcon}
                      className="feature-image"
                      alt="Feature"
                    />
                  </div>
                  <h6>Feature</h6>
                  <p className="feature-message">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                  <div className="feature-listed">
                    <h6>What you will get</h6>
                    <ul>
                      <li>
                        <img src={RightCheck} alt="Check" /> Lorem ipsum dolor
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> amet consectetur
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Egestas mauris
                        parturient
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> leo enim potenti
                        sed{" "}
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Nunc nec tellus
                        turpis phasellus
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> o nunc dui quisque
                        eget.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item feature-main">
                <div className="feature-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={FeatureIcon}
                      className="feature-image"
                      alt="Feature"
                    />
                  </div>
                  <h6>Feature</h6>
                  <p className="feature-message">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                  <div className="feature-listed">
                    <h6>What you will get</h6>
                    <ul>
                      <li>
                        <img src={RightCheck} alt="Check" /> Lorem ipsum dolor
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> amet consectetur
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Egestas mauris
                        parturient
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> leo enim potenti
                        sed{" "}
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> Nunc nec tellus
                        turpis phasellus
                      </li>
                      <li>
                        <img src={RightCheck} alt="Check" /> o nunc dui quisque
                        eget.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </Container>
      </section>
      <section className="testimonial-section">
        <Container>
          <div className="section-headding">
            <h4>Testimonial</h4>
          </div>
          <div className="testimonial-item">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={20}
              nav={true}
              dots={true}
              center={true}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 3,
                },
              }}
            >
              <div className="item testimonial-main">
                <div className="testimonial-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={QuotexIcon}
                      className="testimonial-image"
                      alt="testimonial"
                    />
                  </div>
                  <h6>
                    OHHHH Thanks god !!!! Finally there is someone making it for
                    me to use on my projects. Love ya{" "}
                  </h6>
                  <div className="star-icon">
                    <img src={StarIcon} />
                  </div>

                  <div className="testimonial-listed">
                    <div className="test-user">
                      <img src={UserIcon} />
                    </div>
                    <div className="testimonial-details">
                      <h6>Dianne Russell</h6>
                      <p>Lead designer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item testimonial-main">
                <div className="testimonial-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={QuotexIcon}
                      className="testimonial-image"
                      alt="testimonial"
                    />
                  </div>
                  <h6>
                    OHHHH Thanks god !!!! Finally there is someone making it for
                    me to use on my projects. Love ya{" "}
                  </h6>
                  <div className="star-icon">
                    <img src={StarIcon} />
                  </div>

                  <div className="testimonial-listed">
                    <div className="test-user">
                      <img src={UserIcon} />
                    </div>
                    <div className="testimonial-details">
                      <h6>Dianne Russell</h6>
                      <p>Lead designer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item testimonial-main">
                <div className="testimonial-card">
                  <div className="main-carousel-card-img">
                    <img
                      src={QuotexIcon}
                      className="testimonial-image"
                      alt="testimonial"
                    />
                  </div>
                  <h6>
                    OHHHH Thanks god !!!! Finally there is someone making it for
                    me to use on my projects. Love ya{" "}
                  </h6>
                  <div className="star-icon">
                    <img src={StarIcon} />
                  </div>

                  <div className="testimonial-listed">
                    <div className="test-user">
                      <img src={UserIcon} />
                    </div>
                    <div className="testimonial-details">
                      <h6>Dianne Russell</h6>
                      <p>Lead designer</p>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </Container>
      </section>
      <section className="contact-section">
        <Container>
          <div className="section-headding">
            <h4>Contact Us</h4>
          </div>
          <Row gutter={24}>
            <Col span={24}>
              <div className="contacts-box">
                <h2>Get in touch with us. We're here to assist you.</h2>
                <div className="social-media-icon">
                  <ul>
                    <li>
                      <a>
                        <img src={FacebookIcon} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={InstagramIcon} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src={TwitterIcon} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col span={24} md={8}>
              <div className="form-inn">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="contact-input"
                />
              </div>
            </Col>
            <Col span={24} md={8}>
              <div className="form-inn">
                <input
                  type="email"
                  placeholder="Email Address"
                  className="contact-input"
                />
              </div>
            </Col>
            <Col span={24} md={8}>
              <div className="form-inn">
                <input
                  type="text"
                  placeholder="Phone Number (optional)"
                  className="contact-input"
                />
              </div>
            </Col>
            <Col span={24}>
              <div className="form-inn">
                <textarea
                  placeholder="Message"
                  className="contact-textarea"
                ></textarea>
              </div>
            </Col>
            <div className="contact-form-btn">
              <Button type="submit" className="submit-button">
                Leave us message
              </Button>
            </div>
          </Row>
        </Container>
      </section>
      <section className="newsletter-section">
        <Container>
          <Row gutter={32}>
            <Col span={24} md={12}>
              <div className="news-menus">
                <h4>Subcribe to our Newsletter</h4>
                <p>
                  Subscribe for Updates: Stay informed about the latest investor
                  updates, financial results, and announcements by subscribing
                  to our newsletter.{" "}
                </p>
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="news-form-inn">
                <input
                  type="text"
                  placeholder="Phone Number (optional)"
                  className=""
                />
                <Button>Subscribe</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="footer-section">
        <Container>
          <Row>
            <Col span={24}>
              <div className="footer-logo">
                <img src={Logo} />
              </div>
            </Col>
            <Col span={24} sm={8} md={8}>
              <div className="footer-social">
                <div className="icon-box">
                  <img src={MapIcon} />
                </div>

                <a>+1 5589 55488 55</a>
              </div>
            </Col>
            <Col span={24} sm={8} md={8}>
              <div className="footer-social">
                <div className="icon-box">
                  <img src={MapIcon} />
                </div>

                <a>A108 Adam Street New York, NY 535022 United States</a>
              </div>
            </Col>
            <Col span={24} sm={8} md={8}>
              <div className="footer-social">
                <div className="icon-box">
                  {" "}
                  <img src={MailIcon} />
                </div>

                <a>info@example.com</a>
              </div>
            </Col>
            <Col span={24}>
              <div className="d-footer-text">
                <a>Privacy Policy</a>
                <a>Terms & Conditions</a>
                <a>Help</a>
                <a>Faq’s</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
}

export default Home;
