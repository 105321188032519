import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  Modal,
  Select,
  Checkbox,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Logo from "../../assets/images/logo.svg";
import LoginLogo from "../../assets/images/logo-login.svg";
import useRequest from "../../hooks/useRequest";
import useFirebase from "../../hooks/useFirebase";
import { ShowToast, Severty } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import apiPath from "../../constants/apiPath";
import encryptDecrypt from "../../helper/encryptDecrypt";
import lang from "../../helper/langHelper";
import { InputOTP } from "antd-input-otp";

import { Container } from "react-bootstrap";
const { Title } = Typography;
const { Content } = Layout;

const SignIn = () => {
  const [loading, setLoading] = useState(false); 
  const [passwordLoading, setPasswordLoading] = useState(false);
  const { request } = useRequest();
  const [visible, setVisible] = useState(false); 
  const [resetModal, setResetModal] = useState(false);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [otpModal, setOtpModal] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const lang = (text) => text; // Mocking language function

  const handleCancel = () => {
    setIsModalOpen(false);
    setOtpModal(false);
  };

  // Mock sendOTP function
  const sendOTP = (email) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve({ success: true }), 1000); // Mocking successful OTP send
    });
  };

 

  // Handle OTP verification
  const handleVerifyOTP = (values) => {
    setVerifyLoading(true);
    // Mocking OTP verification
    setTimeout(() => {
      console.log("OTP Verified:", values.otp);
      setOtpModal(false); // Close OTP modal on successful verification
      setVerifyLoading(false);
    }, 1000);
  };
  const { fcmToken } = useFirebase();

  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeChange = (checked) => {
    setRememberMe(checked);
  };

  const onSubmit = (values) => {
    const { email, password } = values;

    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);

    const payload = {
      email,
      password,
      device_token: fcmToken,
      device_type: "web",
    };

    setLoading(true);
    request({
      url: apiPath.login,
      method: "POST",
      data: payload,
      onSuccess: (data) => {

        setLoading(false);
        if (data.statusText == "true") {

          var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
          var passwordEncrypt = encryptDecrypt.encryptPassword(values.password);
          
          if (rememberMe?.target?.checked === true || rememberMe === true) {
            localStorage.setItem("rememberMe", true);
            localStorage.setItem("ouUsippetc8S4Ry", passwordEncrypt);
          } else {
            localStorage.removeItem("rememberMe");
            localStorage.removeItem("ykmCe2AYEFTHobn");
            localStorage.removeItem("ouUsippetc8S4Ry");
          }

          localStorage.setItem("ykmCe2AYEFTHobn", emailEncrypt);
          ShowToast(data.message, Severty.SUCCESS);
          setTimeout(() => navigate("/otp"), 200);
          
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleForgotPassword = () => {
    setVisible(true);
  };

  const handleResetPassword = (email) => {
    // Send the OTP to the user's email
    setResetLoading(true);
    let payload = {};
    payload.email = email.email;
    request({
      url: apiPath.forgotPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        var emailResetEncrypt = encryptDecrypt.encryptEmail(email.email);
        localStorage.setItem("UGwdfdR2uHMM24N", emailResetEncrypt);
        setResetLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setVisible(false);
        setOtpModal(true);
        setSelected({ email });
      },
      onError: (error) => {
        setResetLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleReset = (values) => {
    const { email, newPassword } = values;
    setPasswordLoading(true);
    // Reset the password with the new password entered by the user
    let payload = {};

    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;

    payload.password = newPassword;
    request({
      url: apiPath.resetPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setPasswordLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setResetModal(false);
      },
      onError: (error) => {
        setPasswordLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleCancelReset = () => {
    setResetModal(false);
    // form.resetFields()
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("ykmCe2AYEFTHobn");
    const savedPassword = localStorage.getItem("ouUsippetc8S4Ry");

    if (savedEmail && savedPassword) {
      var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
      var originalPassword = encryptDecrypt.decryptEmail(savedPassword);

      form.setFieldsValue({
        email: originalEmail,
        password: originalPassword,
        remember: true,
      });
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  }, []);

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Container className="signin">
          <Row className=" align-items-center">
            <Col span={24}>
              <div className="header">
                <img onClick={() => navigate("/")} src={Logo} alt="" />
              </div>
            </Col>
            <Col span={24}>
              <div className="signup-logo ">
                {/* <Button
                  className="prev-pagebtn mt-2"
                  type="button"
                  onClick={() => navigate("/home")}
                  htmlType="submit"
                >
                  <ArrowLeftOutlined />  Back
                </Button> */}
              </div>
            </Col>
          </Row>
        </Container>
        <div className="signin-box">
          <Row justify="space-around">
            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
              <div className="h-sign-menu">
                {/* <img src={LoginLogo} /> */}

                <Title
                  className="tital-text-sign mb-15"
                  onClick={() => navigate("/")}
                >
                  Login
                </Title>
                <p>Please login to continue our website</p>
              </div>
              <div className="signup-form">
                <Form
                  form={form}
                  onFinish={onSubmit}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username-form"
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: lang("Please enter a valid email address!"),
                      },
                      {
                        max: 255,
                        message: lang(
                          "Email address not more then 255 characters!"
                        ),
                      },
                      {
                        required: true,
                        message: lang("Please enter email!"),
                      },
                    ]}
                  >
                    <Input
                      className="form-of-type"
                      placeholder="Enter Email ID"
                    />
                  </Form.Item>

                  <Form.Item
                    className="username-form"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter password!"),
                      },
                    ]}
                  >
                    <Input.Password
                      className="form-of-type"
                      placeholder="Password"
                    />
                  </Form.Item>

                  <div className="forgot-pass">
                    <Form.Item
                      name="remember"
                      className="remember-type-text"
                      valuePropName="checked"
                    >
                      <Checkbox
                        checked={rememberMe}
                        className="rem-check"
                        onChange={(e) => {
                          setRememberMe(e.target.checked);
                        }}
                      ></Checkbox>
                      Remember my email address
                    </Form.Item>
                    <div className="user-dirct">
                      <span className="ac-create">
                        <Link onClick={() => setIsModalOpen(true)}>Forgot your password?</Link>
                      </span>
                    </div>
                  </div>
                  <Form.Item className="signin-footer ">
                    <Button
                      className="submit-button "
                      type="submit"
                      htmlType="submit"
                      loading={loading}
                      // onClick={() => navigate("/")}
                    >
                      Sign In
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
       
      <Modal
        className="tab_modal"
        open={isModalOpen}
        title={lang("Forgot Password")}
        okText={lang("Send OTP")}
        onCancel={handleCancel}
        okButtonProps={{
          form: "forget-password-form",
          htmlType: "submit",
          loading: resetLoading,
        }}
      >
        <Form
          id="forget-password-form"
          onFinish={handleResetPassword}
          layout="vertical"
        >
          <Form.Item
            className="username-form"
            label={lang("Email Address")}
            name="email"
            rules={[
              {
                type: "email",
                message: lang("Please enter a valid email address!"),
              },
              {
                max: 255,
                message: lang("Email address not more than 255 characters!"),
              },
              {
                required: true,
                message: lang("Please enter your email!"),
              },
            ]}
          >
            <Input
              className="form-of-type"
              autoComplete="off"
              placeholder={lang("Enter Email Address")}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* OTP Verification Modal */}
      <Modal
        className="tab_modal"
        open={otpModal}
        title={lang("Verify OTP")}
        okText={lang("Verify")}
        onCancel={handleCancel}
        okButtonProps={{
          form: "verify-otp-form",
          htmlType: "submit",
          loading: verifyLoading,
        }}
      >
        <Form
          id="verify-otp-form"
          onFinish={handleVerifyOTP}
          layout="vertical"
        >
          
          <Form.Item
              name="otp"
              className="otp-form"
              rules={[
                  { required: true, message: "Please enter the OTP" },
              ]}
          >
              <InputOTP
                  className="form-of-type"
                  length={4}
                  inputType="numeric"
              />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SignIn;
