import React from 'react';
import { Card, Avatar, Button, Row, Col, Typography, Divider,Form, Input,Select } from 'antd';
import { EditOutlined, MailOutlined, PhoneOutlined, CalendarOutlined, ManOutlined } from '@ant-design/icons';
 


const { Title, Text } = Typography;
const handleChange = (value) => {
    console.log(`selected ${value}`);
};
 
const FaqIndex = () => {
  const [form] = Form.useForm();
    
    return (
        <>

      
         <Card className=" home-card help-card">
            <div className='help-text'>
                <h6>FAQ’s</h6>
                <p>Lorem ipsum dolor sit amet consectetur. Sem urna aliquet amet nisl pellentesque scelerisque viverra. In nisl feugiat lobortis ac maecenas nulla id lectus nisl. Commodo leo dignissim nulla donec diam imperdiet aliquet mattis. Nulla pretium nibh magna egestas eget enim.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Sem urna aliquet amet nisl pellentesque scelerisque viverra. In nisl feugiat lobortis ac maecenas nulla id lectus nisl. Commodo leo dignissim nulla donec diam imperdiet aliquet mattis. Nulla pretium nibh magna egestas eget enim.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Sem urna aliquet amet nisl pellentesque scelerisque viverra. In nisl feugiat lobortis ac maecenas nulla id lectus nisl. Commodo leo dignissim nulla donec diam imperdiet aliquet mattis. Nulla pretium nibh magna egestas eget enim.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Sem urna aliquet amet nisl pellentesque scelerisque viverra. In nisl feugiat lobortis ac maecenas nulla id lectus nisl. Commodo leo dignissim nulla donec diam imperdiet aliquet mattis. Nulla pretium nibh magna egestas eget enim.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Sem urna aliquet amet nisl pellentesque scelerisque viverra. In nisl feugiat lobortis ac maecenas nulla id lectus nisl. Commodo leo dignissim nulla donec diam imperdiet aliquet mattis. Nulla pretium nibh magna egestas eget enim.</p>
            </div>
         </Card>
   
     </>
    );
};

export default FaqIndex;
