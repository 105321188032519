import React from "react";
import { Container } from "react-bootstrap"; 
import { Row, Col } from "antd";
import { PhoneOutlined } from "@ant-design/icons";

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <div className="footer-content">
         
        </div>
        <div className="footer-bottom">
  
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
