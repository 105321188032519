import React, { useEffect, useState } from "react";
import { Card, Form } from "antd";
import { useAppContext } from "../context/AppContext";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";

const AboutIndex = () => {
  const { setPageHeading } = useAppContext();
  const { request } = useRequest();
  const [content, setContent] = useState();

  const getAboutUs = () => {
    request({
      url: apiPath.content + `/about-us`,
      method: "GET",
      onSuccess: (data)=>{
        if(data?.status){
          // ShowToast(data?.message, Severty.SUCCESS);
          setContent(data?.data);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (data)=>{
        ShowToast(data?.message, Severty.ERROR);
      }
    })
  }

  useEffect(() => {
    getAboutUs();
    setPageHeading("About Us");
  }, []);
  
  return (
    <>
      <Card className=" home-card help-card">
        <div className="help-text">
          <div>
            <p dangerouslySetInnerHTML={{__html: content?.description}} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default AboutIndex;
