import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Button, Input } from "antd";
import { useAppContext } from "../context/AppContext";
import FacebookIcon from "../assets/images/fb.svg";
import InstagramIcon from "../assets/images/insta.svg";
import TwitterIcon from "../assets/images/twitter.svg";
import PhoneInput from "react-phone-input-2";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";

const HelpIndex = () => {
  const { setPageHeading } = useAppContext();
  const { request } = useRequest();
  const [mobileNumber, setMobileNumber] = useState({
    country_code: "",
    mobile_number: "",
  });
  const [info, setInfo] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const handleChange = (value, data) => {
    var country_code = data?.dialCode;
    console.log(data, value, "-----");
    setMobileNumber({
      country_code: country_code,
      mobile_number: value?.slice(data?.dialCode?.length),
    });
  };

  const handleInfo = (e) => {
    setInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmit = () => {
    const payload = { ...info };
    payload.mobile_number = mobileNumber.mobile_number;
    payload.country_code = mobileNumber.country_code;
    console.log(payload, " ::::::::::: ___________ paylod");

    request({
      url: apiPath.support,
      method: "POST",
      data: { ...payload },
      onSuccess: (data) => {
        if(data?.statusText == "true"){
          ShowToast(data?.message, Severty.SUCCESS);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setPageHeading("Help & Support");
  }, []);

  return (
    <>
      <Card className=" home-card help-card">
        <Row gutter={24}>
          <Col span={24} md={8}>
            <div className="form-inn">
              <Input
                name="name"
                onChange={(e) => handleInfo(e)}
                type="text"
                placeholder="Your Name*"
                className="contact-input"
              />
            </div>
          </Col>
          <Col span={24} md={8}>
            <div className="form-inn">
              <input
                onChange={(e) => handleInfo(e)}
                name="email"
                type="email"
                placeholder="Email Address*"
                className="contact-input"
              />
            </div>
            
          </Col>
          <Col span={24} md={8} className="flagMobileNumber">
            <label style={{ color: "grey" }}>Mobile Number (Optional)</label>
            <div className="username-form" name="mobile">
              <PhoneInput
                className="form-of-phone"
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: false,
                  autoFormat: false,
                  autoComplete: "off",
                }}
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else {
                    return true;
                  }
                }}
                country={"us"}
                onChange={handleChange}
              />
            </div>
          </Col>
          {/* <Col span={24} md={8}>
            <div className="form-inn">
              <input
                onChange={()=>handleInfo()}
                type="text"
                placeholder="Phone Number (optional)"
                className="contact-input"
              />
            </div>
          </Col> */}
          <Col span={24}>
            <div className="form-inn">
              <textarea
                onChange={(e) => handleInfo(e)}
                name="message"
                placeholder="Message*"
                className="contact-textarea"
              ></textarea>
            </div>
          </Col>
          <div className="contact-form-btn">
            <Button
              type="submit"
              onClick={() => onSubmit()}
              className="submit-button"
            >
              Send Message
            </Button>
          </div>
        </Row>
      </Card>
    </>
  );
};

export default HelpIndex;
