import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Form,
  Input,
} from "antd";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useAuthContext } from "../../context/AuthContext";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";

const Index = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthContext();
  const { setPageHeading } = useAppContext();
  const { request } = useRequest();

  const changePassword = (values) => {
    const { old_password, new_password } = values;
    const payload = {};
    if (!old_password || !new_password)
      return ShowToast("Please enter password ", Severty.ERROR);
    setLoading(true);
    payload.new_password = new_password;
    payload.old_password = old_password;
    request({
      url: apiPath.changePassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          logout();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
        ShowToast("Logout Successfully", Severty.SUCCESS);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    console.log(form.getFieldsValue());
  }, [form.getFieldsValue()]);

  useEffect(() => {
    setPageHeading("Change Password");
  }, []);
  return (
    <>
      <div className="profile-container">
        <Card className="profile-card home-card">
          <Form
            form={form}
            layout="vertical"
            onFinish={changePassword}
            className="row-col"
          >
            <div className="headdeng-forms">
              <h4>Change Password</h4>
            </div>
            <Form.Item
              className="username-form"
              label="Old Password"
              name="old_password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: lang("Please enter Old Password!"),
                },
              ]}
            >
              <Input.Password
                className="form-of-type"
                placeholder="Old Password"
              />
            </Form.Item>
            <Form.Item
              className="username-form"
              label="New Password"
              name="new_password"
              dependencies={["old_password"]}
              hasFeedback
              rules={[
                { required: true, message: "Please enter the new password!" },
                {
                  pattern: new RegExp(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                  ),
                  message:
                    "New password at least contain 8 characters, at least contain one capital letter, at least contain one small letter, at  least contain one digit, atleast contain one special character",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("old_password") === value) {
                      return Promise.reject(
                        new Error(
                          "Old password & new password must be different!"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                className="form-of-type"
                placeholder="New Password"
              />
            </Form.Item>
            <Form.Item
              className="username-form"
              label="Confirm New Password"
              name="confirm_new_password"
              dependencies={["new_password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter the confirm password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Confirm password & password does not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                className="form-of-type"
                placeholder="Confirm New Password"
              />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              className="edit-profile-button"
            >
              Update Password
            </Button>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Index;
