import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Layout,
    Button,
    Row,
    Col,
    Typography,
    Form,
    Input,
    Switch,
    Modal,
    Select,
    Checkbox,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Logo from "../../assets/images/logo.svg";
import LoginLogo from "../../assets/images/logo-login.svg";
import VerifiedIcon from "../../assets/images/verified-check.svg";

import useRequest from "../../hooks/useRequest";
import useFirebase from "../../hooks/useFirebase";
import { ShowToast, Severty } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import apiPath from "../../constants/apiPath";
import encryptDecrypt from "../../helper/encryptDecrypt";
import lang from "../../helper/langHelper";
import { Container } from "react-bootstrap";
const { Title } = Typography;
const { Content } = Layout;


// const VerifiedIndex = () => {
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState(false);

//     return (
//         <Layout className="layout-default layout-signin">
//             <Container className="signin">
//                 <Row className="align-items-center">
//                     <Col span={24}>
//                         <div className="header">
//                             <img onClick={() => navigate("/")} src={VerifiedIcon} alt="Verified Icon" />
//                         </div>
//                     </Col>
//                     <Col span={24}>
//                         <div className="signup-logo">
//                             <Button
//                                 className="prev-pagebtn mt-2"
//                                 type="button"
//                                 onClick={() => navigate("/")}
//                             >
//                                 <ArrowLeftOutlined /> Back
//                             </Button>
//                         </div>
//                     </Col>
//                 </Row>
//             </Container>
//             <div className="signin-box">
//                 <Row justify="space-around">
//                     <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
//                         <div className="h-sign-menu">
//                             <img src={LoginLogo} alt="Login Logo" />
//                             <Title className="tital-text-sign mb-15">Verify</Title>
//                             <p>You have successfully verified the account</p>
//                         </div>
//                         <div className="signup-form">
//                             <Form layout="vertical" className="row-col">
//                                 <Form.Item className="signin-footer">
//                                     <Button
//                                         className="submit-button"
//                                         type="primary"
//                                         htmlType="submit"
//                                         loading={loading}
//                                     >
//                                         Continue
//                                     </Button>
//                                 </Form.Item>
//                             </Form>
//                         </div>
//                     </Col>
//                 </Row>
//             </div>
//         </Layout>
//     );
// };



const VerifiedIndex = () => {
        const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
    
        const [loading, setLoading] = useState(false); 
        const { request } = useRequest(); 
        const navigate = useNavigate();
        const [form] = Form.useForm();
    
        const { fcmToken } = useFirebase();
    
        const [rememberMe, setRememberMe] = useState(false);
    
    
    
    
        return (
            <>
                <Layout className="layout-default layout-signin">
                    <Container className="signin">
                        <Row className=" align-items-center">
                            <Col span={24} >
                                <div className="header">
                                    <img onClick={() => navigate("/")} src={Logo} alt="" />
                                </div>
                            </Col>
                            <Col span={24} >
                                <div className="signup-logo ">
                                    <Button
                                        className="prev-pagebtn mt-2"
                                        type="button"
                                        onClick={() => navigate("/")}
                                        htmlType="submit"
                                    >
                                        <ArrowLeftOutlined />  Back
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="signin-box">
                        <Row justify="space-around">
                            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                                <div className="h-sign-menu">
                                    <img src={LoginLogo} />
    
                                    <Title
                                        className="tital-text-sign mb-15"
                                        onClick={() => navigate("/")}
                                    >
                                        Verify
                                    </Title>
                                    <p>You have successfully verified the account</p>
                                </div>
                                <div className="signup-form">
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        className="row-col"
                                    >
                                        <Form.Item className="signin-footer ">
                                            <Button
                                                className="submit-button "
                                                type="submit"
                                                htmlType="submit"
                                                loading={loading}
                                            >
                                                Continue
                                            </Button>
    
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Layout>
            </>
        );
    };
    export default VerifiedIndex;