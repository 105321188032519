import { useState } from "react";
import { Form, Modal, Row, Col, Radio, Button } from "antd";
import lang from "../helper/langHelper";
import LogOut from "../assets/images/logout-icon.svg";

const DeleteModal = ({ show, hide, onOk, title, subtitle }) => {

  return (
    <Modal
      open={show}
      onOk={() => {
        if (onOk) onOk();
        hide();
      }}
      okText={lang("Ok")}
      footer={false}
      cancelText={lang("Cancel")}
      onCancel={hide}
      centered
      title=""
      className="tab_modal "
    >
      <Form id="create" layout="vertical">
        <div className="recived-modal">
          <img src={LogOut} />
          <h3>{title}</h3>
          <p>{subtitle} </p>
          <Button
            onClick={() => {
              hide();
              onOk();
            }}
            className="btn-outers mt-5"
          >
            Logout
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DeleteModal;
